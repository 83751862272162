import * as React from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { motion } from "framer-motion";
import Layout from "../components/layout/layout";
import { NavBar } from "../components/navbar/navbar";
import {
  heroSection,
  mockup,
  branding,
  heroContent,
  navbar,
  heroSecondaryButton,
  blueSection,
  macbookMockup,
  logos,
} from "./index.module.scss";
import {
  container,
  primaryButton,
  secondaryButton,
  flex,
  grid,
  column,
  justifyCenter,
  alignCenter,
  staticImage,
  textAlignCenter,
} from "./../components/layout/layout.module.scss";

import { Contact } from "../components/contact/contact";

const metaData = {
  author: "Andrew Winters",
  title: "Websites for growing your business | Andrew Winters Design",
  description:
    "Build trust and engagement with your brand. Get in touch, and stand out with an attractive website, blog, or online store. ",
  url: "https://www.andrewwintersdesign.co.nz", // No trailing slash allowed!
  image:
    "https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/preview.jpg?alt=media&token=9ed6cf8a-f964-4a5d-8b9c-8ba303a5be50", // Path to the image placed in the 'static' folder, in the project's root directory.
  og: {
    siteName: "Websites for growing your business | Andrew Winters Design",
    description:
      "Stand out with a professional website, blog, or online store.",
  },
};

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout
        pageTitle={metaData.title}
        ogTitle={metaData.og.siteName}
        ogDescription={metaData.og.description}
        description={metaData.description}
        image={metaData.image}
      >
        <section className={`${heroSection} `}>
          <div className={`${navbar} `}>
            <NavBar />
          </div>

          <div className={`${container} ${heroContent}`}>
            <motion.h1
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ type: "ease", stiffness: 200, duration: 0.2 }}
            >
              Websites To Elevate Your Brand
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{
                type: "ease",
                stiffness: 200,
                duration: 0.2,
                delay: 0.1,
              }}
            >
              Stand out with a professional website, blog, or online store.
              <br /> Get in touch for a free quote.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{
                type: "ease",
                stiffness: 200,
                duration: 0.2,
                delay: 0.2,
              }}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                className={primaryButton}
                transition={{ type: "spring", stiffness: 200 }}
                onClick={() => scrollTo("#learn-more")}
              >
                Learn More
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                className={`${secondaryButton} ${heroSecondaryButton}`}
                transition={{ type: "spring", stiffness: 200 }}
                onClick={() => scrollTo("#get-in-touch")}
              >
                Get in touch
              </motion.button>
            </motion.div>
            {/* <CarouselProvider
                naturalSlideWidth={16}
                naturalSlideHeight={11}
                totalSlides={3}
                isPlaying={true}
                interval={5000}
                infinite={true}
              >
                <Slider className={slider}>
                  <Slide index={0}>
                    <StaticImage
                      src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/slide1.png?alt=media&token=cc29d632-3702-4354-90a0-6ca0618d067f"
                      alt="Coffee website mockup"
                      className={` ${staticImage}`}
                    />
                  </Slide>
                  <Slide index={1}>
                    <StaticImage
                      src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/slide2.png?alt=media&token=0ba9bea9-c4aa-4611-ab70-5863e61750bf"
                      alt="plant shop website mockup"
                      className={` ${staticImage}`}
                    />
                  </Slide>
                  <Slide index={2}>
                    <StaticImage
                      src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/slide3.png?alt=media&token=663fb2bc-4294-4082-94d2-6b7948710c43"
                      alt="Smart Home website mockup"
                      className={` ${staticImage}`}
                    />
                  </Slide>
                </Slider>
              </CarouselProvider> */}
          </div>
          <div></div>
        </section>
        <section>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: "ease", stiffness: 200, duration: 0.2 }}
            className={` ${container} ${flex} ${column} ${alignCenter} ${justifyCenter}`}
            id="learn-more"
          >
            <div
              className={`${flex} ${column} ${alignCenter} ${justifyCenter} ${textAlignCenter}`}
            >
              <motion.h2
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.1,
                }}
                viewport={{ once: true }}
              >
                Why You Need A Web Designer
              </motion.h2>

              <div className={textAlignCenter}>
                <motion.h3
                  whileInView={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 100 }}
                  transition={{
                    type: "ease ",
                    stiffness: 100,
                    duration: 0.3,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  The first thing people&nbsp;see
                </motion.h3>
                <motion.p
                  whileInView={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 100 }}
                  transition={{
                    type: "ease ",
                    stiffness: 100,
                    duration: 0.3,
                    delay: 0.3,
                  }}
                  viewport={{ once: true }}
                >
                  Your website is the first chance for you to make an impression
                  on people. Weak design can lead to people leaving and taking
                  their money with them.
                </motion.p>

                <motion.h3
                  whileInView={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 100 }}
                  transition={{
                    type: "ease ",
                    stiffness: 100,
                    duration: 0.3,
                    delay: 0.4,
                  }}
                  viewport={{ once: true }}
                >
                  Get help to get it right
                </motion.h3>
                <motion.p
                  whileInView={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 100 }}
                  transition={{
                    type: "ease ",
                    stiffness: 100,
                    duration: 0.3,
                    delay: 0.5,
                  }}
                  viewport={{ once: true }}
                >
                  We ensure your website is a fantastic representation of what
                  your brand values and offers. The result will be the engaging,
                  dynamic, responsive website you need.
                </motion.p>
              </div>
            </div>
          </motion.div>
        </section>
        <section className={` ${blueSection}`}>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: "ease", stiffness: 200, duration: 0.2 }}
            className={`${container}  ${flex} ${column} ${alignCenter} ${textAlignCenter}`}
          >
            <motion.h2
              whileInView={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 100 }}
              transition={{
                type: "ease ",
                stiffness: 100,
                duration: 0.3,
                delay: 0.1,
              }}
              viewport={{ once: true }}
            >
              The Perfect Platform For Your Needs
            </motion.h2>

            <motion.p
              whileInView={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 100 }}
              transition={{
                type: "ease ",
                stiffness: 100,
                duration: 0.3,
                delay: 0.2,
              }}
              viewport={{ once: true }}
            >
              Whether you need a powerful online store, a user-friendly content
              system, blog functionality, or something super customised, there
              is a site platform for you. <br />
              <br />
              With our experience building and designing sites on all of the
              popular web technologies and platforms out there means we can help
              you to choose the right one for your business. We take out all of
              the confusion to ensure you have the website you always wanted,
              the first time.
            </motion.p>
            <div className={` ${logos}`}>
              <motion.img
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.1,
                }}
                viewport={{ once: true }}
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/wordpress-logo.svg?alt=media&token=1b50f576-3432-4f2a-a24a-02cdab4bb103"
                alt="Wordpress logo"
              />
              <motion.img
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.2,
                }}
                viewport={{ once: true }}
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/shopify-lgo.svg?alt=media&token=279e8216-5762-43e8-8562-0679c106890a"
                alt="Shopify logo"
              />
              <motion.img
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.3,
                }}
                viewport={{ once: true }}
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/squarespace-logo.svg?alt=media&token=c3d78bff-8b47-429c-9405-b67724514a31"
                alt="Squarespace logo"
              />

              <motion.img
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.4,
                }}
                viewport={{ once: true }}
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/wix-logo.svg?alt=media&token=fd41bf1b-9b54-4fb3-88f9-959242458a24"
                alt="Wix logo"
              />
              <motion.img
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.4,
                }}
                viewport={{ once: true }}
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/webflow-logo.svg?alt=media&token=ef017843-bd62-4eb5-9b05-aa61707f858c"
                alt="Webflow logo"
              />

              <motion.img
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.5,
                }}
                viewport={{ once: true }}
                src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/web-logos.svg?alt=media&token=6678f9b3-5108-49da-8669-579c0ebda620"
                alt="HTML, CSS, and Javascript logos"
              />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: "ease", stiffness: 200, duration: 0.2 }}
            className={` ${macbookMockup}`}
          ></motion.div>
        </section>
        <section>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ type: "ease", stiffness: 200, duration: 0.2 }}
            className={`${container}`}
          >
            <div className={`${grid}  ${alignCenter} ${justifyCenter} `}>
              <div>
                <motion.h2
                  whileInView={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 100 }}
                  transition={{
                    type: "ease ",
                    stiffness: 100,
                    duration: 0.3,
                    delay: 0.1,
                  }}
                  viewport={{ once: true }}
                >
                  Mobile First
                </motion.h2>
                <motion.p
                  whileInView={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 100 }}
                  transition={{
                    type: "ease ",
                    stiffness: 100,
                    duration: 0.3,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  Did you know over half of your potential customers will visit
                  your website on a mobile device? Having a website that
                  performs poorly on mobile devices can be disastrous.
                  <br />
                  <br />
                  We design websites with all devices in mind so your brand
                  looks great for anyone who views it.
                  <br />
                </motion.p>
              </div>

              <motion.div
                whileInView={{ opacity: 1, x: 0 }}
                initial={{ opacity: 0, x: 100 }}
                transition={{
                  type: "ease ",
                  stiffness: 100,
                  duration: 0.3,
                  delay: 0.3,
                }}
                viewport={{ once: true }}
              >
                <StaticImage
                  viewport={{ once: true }}
                  src="https://firebasestorage.googleapis.com/v0/b/awd-portfolio.appspot.com/o/Mockup-group.webp?alt=media&token=ef359478-5458-4779-ba24-1cc481619c38"
                  alt="Coffee website mockup"
                  className={`${mockup} ${staticImage}`}
                />
              </motion.div>
            </div>
          </motion.div>
        </section>

        <Contact />
      </Layout>
    );
  }
}
